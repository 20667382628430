<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="m10.9 2.1 9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1Zm.707 2.122L3.827 12l8.487 8.485 7.778-7.778-1.06-7.425-7.425-1.06Zm2.12 6.364a2.001 2.001 0 1 1 2.83-2.83 2.001 2.001 0 0 1-2.83 2.83Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
